import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '@/actions/auth';
import Image from 'next/image';
import { isLogin } from '@/helpers/loginHelper';
import Head from 'next/head';
import { Button, Modal, Row } from 'antd';
import useWidth from '@/helpers/widthUtil';
import { CloseOutlined } from '@ant-design/icons';
import { Col } from 'reactstrap';
import Link from 'next/link';
import CustomCourseCarousel from '@/components/CustomCourseCarousel';
import LandingLearnings from '@/components/LandingLearnings';
import Onboarding from '../onboarding';

const MainBanner = ({
  mission,
  isLoginPage = false,
  backgroundColorWhite = false,
  isSignUpPage = false,
  myLearnings = [],
}) => {
  const dispatch = useDispatch();
  const { isMobile, isDesktopXL } = useWidth();
  const [visible, setVisible] = useState(false);
  const [auth, setAuth] = useState(true);
  const user = useSelector((state: any) => state.auth?.creds?.user_data);
  const getHomeStatus = useSelector((state: any) => state.home?.getHomeStatus);

  useEffect(() => {
    dispatch(
      setData({
        name: 'register',
        key: 'error',
        value: {},
      })
    );
    dispatch(
      setData({
        name: 'register',
        key: 'status',
        value: isSignUpPage ? true : false,
      })
    );
  }, []);

  useEffect(() => {
    if (!isLogin()) {
      setAuth(false);
    }
  }, []);

  const closeModal = () => {
    setVisible(false);
    let cl: any = document.getElementsByClassName('learnsic-course-enrol');
    cl[0]?.contentWindow.postMessage(
      JSON.stringify({ event: 'command', func: 'stopVideo', args: '' }),
      '*'
    );
  };

  const openModal = () => {
    setVisible(true);
    let cl: any = document.getElementsByClassName('learnsic-course-enrol');
    cl[0]?.contentWindow.postMessage(
      JSON.stringify({ event: 'command', func: 'playVideo', args: '' }),
      '*'
    );
  };

  return (
    <>
      <Modal
        className="landing-video"
        closable={false}
        width={960}
        visible={visible}
        footer={null}
        onCancel={closeModal}
        bodyStyle={{ padding: 0, width: '100%', height: '100%' }}
      >
        <CloseOutlined
          rev=""
          className="video-close-btn cursor-pointer"
          onClick={closeModal}
          title="Close Video"
        />
        <div className="landing-video-wrapper bg-transparent">
          <iframe
            id="learnsic-course-enrol"
            className="learnsic-course-enrol border-14"
            src="https://www.youtube.com/embed/3DegA3E3XJs?version=3&enablejsapi=1&autoplay=1&rel=0&loop=1&playlist=3DegA3E3XJs"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>

      <Head>
        <link rel="preload" as="image" href="/images/main_banner.webp" />
      </Head>
      <div className={`${!isLoginPage && !isSignUpPage && 'main-banner pb-0'}`}>
        <div className="custom-container">
          <div className="row">
            <div className="col-lg-7 col-md-12">
              {!isLoginPage && !isSignUpPage ? (
                <div className={`main-banner-content ${isMobile ? 'pr-0' : 'pr-5'}`}>
                  <h1 className="title montserrat-font-family">
                    <div className="text-primary"> Better learning</div>Starts from
                    here
                  </h1>
                  <div
                    className="font-md"
                    dangerouslySetInnerHTML={{ __html: mission }}
                  ></div>
                  <div className="d-none d-lg-block">
                    <div className="d-flex flex-row mt-4">
                      <Link href="/course" prefetch={false}>
                        <a>
                          <Button
                            size={'large'}
                            className="cursor-pointer btn-danger text-white border-danger pl-3 pr-3 montserrat-font-family"
                          >
                            Explore Course
                          </Button>
                        </a>
                      </Link>
                      <Button
                        onClick={openModal}
                        size={'large'}
                        className={`cursor-pointer btn-outline-danger border-danger pl-3 pr-3 montserrat-font-family ml-3`}
                      >
                        Watch Video
                      </Button>
                    </div>
                  </div>
                  <div className="d-sm-block d-md-block d-lg-none pt-4 pb-4">
                    {/* <div className="d-flex justify-content-center w-75 mx-auto flex-column"> */}
                    <div style={{ display: isMobile ? 'inline-block' : '' }}>
                      {!isLogin() ? (
                        <Link href="/signup" prefetch={false}>
                          <a>
                            <Button
                              size={'large'}
                              className={`cursor-pointer btn-outline-danger border-danger pl-3 pr-3 montserrat-font-family  ${
                                isMobile ? 'mr-2' : 'mr-3'
                              }`}
                            >
                              Sign up
                            </Button>
                          </a>
                        </Link>
                      ) : (
                        <Button
                          onClick={openModal}
                          size={'large'}
                          className={`cursor-pointer btn-outline-danger border-danger pl-3 pr-3 mb-2 montserrat-font-family ${
                            isMobile ? 'mr-2' : 'mr-3'
                          }`}
                        >
                          Watch Video
                        </Button>
                      )}
                      <Link href="/course" prefetch={false}>
                        <a>
                          <Button
                            size={'large'}
                            className="cursor-pointer btn-danger text-white border-danger pl-3 pr-3 montserrat-font-family mt-3"
                          >
                            Explore Course
                          </Button>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>

            {!auth ? (
              <div
                className={`col-lg-5 col-md-12 login-contents ${
                  !isLoginPage && !isSignUpPage ? 'd-none d-lg-block' : ''
                }`}
              >
                <div
                  className={`banner-form py-3 ${!isMobile && 'pl-5'}`}
                  style={{
                    paddingLeft: isDesktopXL ? '5rem !important' : '4rem !important',
                  }}
                >
                  <div
                    className="p-4 border-18"
                    style={{
                      background: backgroundColorWhite
                        ? !isMobile
                          ? '#fff'
                          : '#f8f8f8'
                        : '#f8f8f8',
                    }}
                  >
                    <Onboarding
                      is_instructor={false}
                      isLoginPage={isLoginPage}
                      isSignUpPage={isSignUpPage}
                    />
                  </div>
                </div>
              </div>
            ) : (
              !isMobile && (
                <div className="col-lg-5 col-md-12 align-items-right">
                  <div className="banner-image text-center">
                    <span>
                      <Image
                        src="/images/main_banner.webp"
                        alt="image"
                        width="550px"
                        height="500px"
                        className="tranform-up-hover box-shadow-hover"
                        priority
                      />
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
          {!auth
            ? !isLoginPage &&
              !isSignUpPage &&
              !isMobile && (
                <section className="icon-box-area bg-light mb-4 p-3 border-14 extra-content-banner d-none d-lg-block">
                  <Row className="justify-content-center align-items-center">
                    <Col md="4" sm="12" className={`${isMobile ? 'mb-2' : ''}`}>
                      <div className="d-flex flex-column">
                        <div className="text-center">
                          <Image
                            src="/images/svgs/landing/mentor.svg"
                            width={36}
                            height={36}
                          />
                        </div>
                        <div className="mt-2">
                          <h6 className="text-center text-dark font-size-small">
                            Experienced Mentors
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="4" sm="12" className={`${isMobile ? 'mt-2 mb-2' : ''}`}>
                      <div className="d-flex flex-column">
                        <div className="text-center">
                          <Image
                            src="/images/svgs/landing/video.svg"
                            width={36}
                            height={36}
                          />
                        </div>
                        <div className="mt-2">
                          <h6 className="text-center text-dark font-size-small">
                            Quality Video
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col md="4" sm="12" className={`${isMobile ? 'mt-2 mb-2' : ''}`}>
                      <div className="d-flex flex-column">
                        <div className="text-center">
                          <Image
                            src="/images/refer/discount.svg"
                            width={36}
                            height={36}
                          />
                        </div>
                        <div className="mt-2">
                          <h6 className="text-center text-dark font-size-small">
                            Affordable Prices
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
              )
            : getHomeStatus !== 'request' &&
              myLearnings?.length !== 0 && (
                <section className="mb-30 border-14">
                  <h3
                    className="mb-4 pl-2 font-size-medium text-primary"
                    style={{
                      // color: '#283360',
                      fontSize: '24px !important',
                      fontWeight: '700 !important',
                    }}
                  >
                    Continue your learning, {user?.first_name}
                  </h3>
                  <div className="row">
                    {myLearnings?.length <= 3 && !isMobile ? (
                      myLearnings?.map((course) => (
                        <LandingLearnings key={course?.id} each={course} />
                      ))
                    ) : (
                      <CustomCourseCarousel myLearnings courses={myLearnings} />
                    )}
                  </div>
                </section>
              )}
        </div>
      </div>
    </>
  );
};

export default memo(MainBanner);
