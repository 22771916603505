import React, { FC, useEffect, useState } from 'react';
import Link from 'next/link';
import APIServices from '@/apiUtils/APIServices';
import { Input, notification } from 'antd';
import Image from 'next/image';
import { SocialMediaIcons, socialMediaImages } from '@/helpers/dataHelper';
import useWidth, { IBooleanArgs } from '@/helpers/widthUtil';
declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: any;
  }
}
const Footer: FC<{}> = () => {
  const { isGalaxyZFold }: IBooleanArgs = useWidth();
  let currentYear: number = new Date().getFullYear();
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = '9206d42b-9db6-4636-a4f2-c83fb5030add';

      (function () {
        var d: Document = document;
        var s: HTMLScriptElement = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1 as any;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }, []);
  return (
    <>
      <footer
        className={`footer-section pt-100 pb-70 border-bottom border-dark ${
          isGalaxyZFold ? '' : 'pl-5 pr-5'
        }`}
      >
        <div className="custom-container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-area">
                <div className="footer-heading">
                  <h3>Learnsic</h3>
                </div>
                <p>Better learning start from here</p>

                <ul className="footer-social">
                  <li key={`social-media-facebook`}>
                    <Link
                      href="https://www.facebook.com/learnsic.official"
                      prefetch={false}
                    >
                      <a className="icon" target="__blank">
                        <i className={`fab fa-facebook`}></i>
                      </a>
                    </Link>
                  </li>
                  <li key={`social-media-instagram`}>
                    <Link
                      href="https://www.instagram.com/learnsic.official"
                      prefetch={false}
                    >
                      <a className="icon" target="__blank">
                        <i className={`fab fa-instagram`}></i>
                      </a>
                    </Link>
                  </li>
                </ul>

                <div className="rounded-social-buttons">
                                {social_media?.map((data) => (
                                    <li>
                                        <Link href={data?.url}>
                                            <a className="icon">
                                                <i className={`fab fa-${data?.name}`}></i>
                                            </a>
                                        </Link>
                                    </li>
                                ))}
                                </div>
              </div>
            </div> */}
            <div className="row col-lg-8 col-8 col-12">
              <div className="col-lg-5 col-md-6 col-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="footer-item-area">
                  <div className="footer-heading">
                    <h3>Links</h3>
                  </div>

                  <ul className="footer-quick-links">
                    {/* <li>
                      <Link href="/tutor/our-tutors" prefetch={false}>
                        <a>Our Instructors</a>
                      </Link>
                    </li> */}
                    <li>
                      <Link href="/about-us" prefetch={false}>
                        <a>About Us</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact" prefetch={false}>
                        <a>Contact</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/request-course" prefetch={false}>
                        <a>Request Course</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/book-online" prefetch={false}>
                        <a>Book Class</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/faq" prefetch={false}>
                        <a>FAQ</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/signup/partner" prefetch={false}>
                        <a>Become Partner</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/become-ambassador" prefetch={false}>
                        <a>Become College Ambassador</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-7 col-md-6 col-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div className="footer-item-area">
                  <div className="footer-heading">
                    <h3>Featured</h3>
                  </div>

                  <ul className="footer-quick-links">
                    <li>
                      <Link href="/abroad" prefetch={false}>
                        <a>Study Abroad</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/course" prefetch={false}>
                        <a>Course</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/mock-test" prefetch={false}>
                        <a>Mock Test</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/blog" prefetch={false}>
                        <a>Blog</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/room" prefetch={false}>
                        <a>Room</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/notes" prefetch={false}>
                        <a>Notes</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer-item-area">
                <div className="footer-heading">
                  <h3>Legal</h3>
                </div>
                <ul className="footer-quick-links">
                  <li>
                    <Link href="/terms-conditions" prefetch={false}>
                      <a>Terms and Conditions</a>
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <SubscribeFormWrapper />
          </div>
        </div>
      </footer>

      {/* Copyright footer */}
      <div className="copyright-area position-relative " style={{ zIndex: 1 }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <p>© {currentYear} Learnsic | All Rights Reserved</p>
            </div>

            <div className="col-lg-6">
              <ul>
                <li>
                  <Link href="/terms-conditions" prefetch={false}>
                    <a>Terms & Conditions</a>
                  </Link>
                </li>
                <li>
                  <Link href="/privacy-policy" prefetch={false}>
                    <a>Privacy Policy</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

const SubscribeFormWrapper = (_) => {
  const [email, setEmail] = useState('');
  const onSubscribe = async (e) => {
    e.preventDefault();
    const { data, success } = await new APIServices('email-subscribe/').post({
      email,
    });
    if (success) {
      notification.success({ message: 'Email Subscribed Successfully' });
      setEmail('');
    } else {
      notification.error({ message: data?.error?.email });
    }
  };
  return (
    <div className="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="footer-heading">
        <h3>Newsletter</h3>
      </div>
      <p className="mt-4" style={{ color: '#e0dbdb' }}>
        Subscribe and receive the latest tips via email.
      </p>
      <form onSubmit={onSubscribe}>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <div className="foot-subscribe form-group position-relative">
              <label className="text-light" style={{ color: 'e0dbdb' }}>
                Write your email <span className="text-danger">*</span>
              </label>
              <Input
                type="email"
                id="email"
                value={email}
                onChange={({ target: { value } }) => setEmail(value)}
                // className="form-control rounded w-100"
                className="rounded border-14"
                placeholder="Your email : "
                required
                size="large"
              />
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <input
              type="submit"
              id="submitsubscribe"
              name="send"
              // className="btn btn-secondary w-100 font-weight-normal rounded"
              className="btn btn-secondary border border-danger border-14 border-hover-primary rounded font-weight-normal w-100"
              value="Subscribe"
            />
            <div className="d-flex mt-4 ml-5 mr-5 justify-content-between">
              {socialMediaImages?.map((each, key) => (
                <div className="" key={key}>
                  <Link href={each?.url}>
                    <a target="__blank" title={each?.title}>
                      <SocialMediaIcons src={each?.image} key={each?.id} />
                    </a>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
